<template>
  <div class="square-block">
    <div class="card bg-dark">
      <div class="card-body">
        <div class="input-group">
          <input v-model="CSirtSearch" type="text" class="form-control form-control-sm">
          <div class="input-group-append">
            <button class="btn btn-sm btn-dark" type="button">Cerca</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card bg-dark">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-dark">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Severity</th>
            <th scope="col">Host</th>
            <th scope="col">Malware</th>
            <th scope="col">Data Breach</th>
            <th scope="col">Data</th>
            <th scope="col">Azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">234</th>
            <td><span class="badge bg-danger">Critical</span></td>
            <td>192.168.1.37</td>
            <td>CVE-2024-0345</td>
            <td>Si</td>
            <td>16 Jan 2024</td>
            <td>
              <router-link class="btn btn-sm btn-dark action" to="/reports/report-csirt.pdf" target="_blank" title="Scarica report">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/>
                </svg>
              </router-link>
            </td>
          </tr>
          <tr>
            <th scope="row">235</th>
            <td><span class="badge bg-success">Low</span></td>
            <td>192.168.1.39</td>
            <td>CVE-2023-42782</td>
            <td>No</td>
            <td>16 Jan 2024</td>
            <td>
              <router-link class="btn btn-sm btn-dark action" to="/reports/report-csirt.pdf" target="_blank" title="Scarica report">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/>
                </svg>
              </router-link>
            </td>
          </tr>
          <tr>
            <th scope="row">236</th>
            <td><span class="badge bg-warning">Medium</span></td>
            <td>192.168.1.39</td>
            <td>CVE-2023-42782</td>
            <td>No</td>
            <td>16 Jan 2024</td>
            <td>
              <router-link class="btn btn-sm btn-dark action" to="/reports/report-csirt.pdf" target="_blank" title="Scarica report">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/>
                </svg>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: 'CSIRTWidget',
  components :{

  },
  computed : {
    CSirtSearch(){
      return this.$store.getters.getCSirtSearch;
    }
  },
  data() {
    return {}
  },
  methods:{

  }
}
</script>