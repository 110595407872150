<template>
  <template v-if="errors.length > 0">
    <div class="alert alert-danger mt-3" v-for="(error,index) in errors" :key="index">
      {{error}}
    </div>
  </template>
  <form @submit.prevent="login()">
    <div class="mb-3">
      <label for="email" class="form-label text-dark">Email</label>
      <input type="email" v-model="email" class="form-control" id="email">
    </div>
    <div class="mb-3">
      <label for="pw" class="form-label text-dark">Password</label>
      <input type="password" v-model="password" class="form-control" id="pw">
    </div>
    <button type="submit" class="btn btn-dark">Log In</button>
  </form>
</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
  // eslint-disable-next-line
  name: 'LoginForm',
  data() {
    return {
      email: null,
      password: null,
      showPassword : false,
      errors : []
    }
  },
  methods : {
    async login(){
      this.errors = [];
      if(this.email && this.password) {
        this.$store.commit("setSpinner",true);
        await axios.post('login', {
          email: this.email,
          password: this.password
        }).then((res) => {
          localStorage.setItem('expires', Math.floor(Date.now() / 1000) + res.data.expires_in);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("refreshtoken", res.data.refreshtoken);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          this.$store.commit("setAuth", true);
          this.$store.commit("setSpinner",false);
          router.push("/");
        }).catch((error) => {
          this.$store.commit("setSpinner",false);
          console.log(error);
          if (error.response && error.response.status === 401) {
            this.errors.push('Incorrect username or password');
          } else {
            this.errors.push('Login technical problems. Please retry later.');
          }
        });
      }else{
        this.errors.push('Empty username or password');
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
