<template>
  <div class="col-lg-12">
    <div class="container text-center">
      <div class="row">
        <div class="colg-lg-12">
          <h1 class="mb-5">OOPS, you broke the Internet!</h1>
          <p>Just kidding. We can't seem to find the page you're looking for.</p>
          <p>ERROR CODE: 404</p>
          <router-link to="/" class="btn btn-outline-light mt-4">Return to homepage</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'NotFound',
  computed : {

  },
  components :{

  },
  methods:{

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
