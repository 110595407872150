import axios from "axios";

export default {
    getAuth : (state) => {
        return state.auth;
    },
    getSpinner(state) {
        return state.spinner;
    },
    getModalSettings(state) {
        return state.modalSettings;
    },
    getModalTicket(state) {
        return state.modalTicket;
    },
    getModalMitre(state) {
        return state.modalMitre;
    },
    getModalNewWidget(state){
        return state.modalNewWidget;
    },
    getTicketsSearch(state){
        return state.ticketsSearch;
    },
    getRedTeamSearch(state){
        return state.redTeamSearch;
    },
    getThreadIntSearch(state){
        return state.threadIntSearch;
    },
    getCSirtSearch(state){
        return state.CSirtSearch;
    },
    getMenu(state) {
        return state.menu;
    },
    getDrawer(state) {
        return state.drawer;
    },
    getMessages(state) {
        return state.messages;
    },
    getChatCursor(state) {
        return state.chatCursor;
    },
    getChats(state) {
        return state.chats;
    },
    getIdChat(state){
        return state.idchat;
    },
    getTab(state){
        return state.tab;
    },
    getNumberInterval(state){
        return state.numberInterval;
    },
    manageAuth: async (state) => {
        let token = localStorage.getItem("token");
        let expires = localStorage.getItem("expires");
        let refreshtoken = localStorage.getItem("refreshtoken");
        if(token && expires && token.length > 0 && expires >= Math.floor(Date.now() / 1000)) {
            state.auth = true;
        }else if(refreshtoken){
            //token scaduto
            await axios.get("refreshtoken?t=" + refreshtoken).then(resp => {
                localStorage.setItem('expires', Math.floor(Date.now() / 1000) + resp.data.expires_in);
                localStorage.setItem("token",resp.data.token);
                localStorage.setItem("refreshtoken",resp.data.refreshtoken);
                localStorage.setItem("user",JSON.stringify(resp.data.user));
                state.auth = true;
            }).catch((error) => {
                localStorage.removeItem("expires");
                localStorage.removeItem("token");
                localStorage.removeItem("refreshtoken");
                localStorage.removeItem("user");
                state.auth = false;
                console.log(error);
            });
        }else{
            //logout
            localStorage.removeItem("expires");
            localStorage.removeItem("token");
            localStorage.removeItem("refreshtoken");
            localStorage.removeItem("user");
            state.auth = false;
        }
    }
}