<template>
  <div class="modal" :class="{show : modalSettings}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">Chat parameters</h5>
          <button type="button" class="btn-close" v-on:click="toggleSettings"/>
        </div>
        <div class="modal-body">
            <label for="length" class="form-label">Max length</label>
            <input type="range" class="form-range" min="0" max="5" step="0.5" value="2" id="length">
            <label for="rpenalty" class="form-label">Repetition penalty</label>
            <input type="range" class="form-range" min="0" max="5" step="0.5" value="0" id="rpenalty">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" v-on:click="toggleSettings">Close</button>
          <button type="button" class="btn btn-dark" v-on:click="changeModel">Change</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'ModalSettings',
  data() {
    return {}
  },
  computed: {
    modalSettings(){
      return this.$store.getters.getModalSettings;
    }
  },
  methods : {
    toggleSettings(){
      this.$store.commit("setModalSettings", !this.modalSettings);
    },
    changeModel(){
      this.$store.dispatch("changeModel");
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-dialog {
  z-index: 1;
}

.show{
  display: block;
}

.modal-dialog {
  z-index: 1;
}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

label{
  color: #000;
}
</style>
