<template>
  <div class="modal" :class="{show : modalTicket}">
    <div class="modal-dialog modal-xxl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">Ticket Details</h5>
          <button type="button" class="btn-close" v-on:click="toggleModal"/>
        </div>
        <div class="modal-body">
          <div class="form-group mb-2">
            <label>Title</label>
            <input type="text" class="form-control" value="Attack pending"/>
          </div>
          <div class="form-group mb-2">
            <label>Message</label>
            <textarea class="form-control">Pending attack Modifies Gunter's Winlogon Registry Key</textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm">Send Reply</button>
          <button type="button" class="btn btn-secondary btn-sm" v-on:click="toggleModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'ModalTicket',
  data() {
    return {}
  },
  computed: {
    modalTicket(){
      return this.$store.getters.getModalTicket;
    }
  },
  methods : {
    toggleModal(){
      this.$store.commit("setModalTicket", !this.modalTicket);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-dialog {
  z-index: 1;
}

.show{
  display: block;
}

.modal-dialog {
  z-index: 1;
}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

label{
  color: #000;
}

.modal-xxl{
  max-width: 1200px;
}
</style>
