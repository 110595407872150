<template>
  <div class="square-block">
    <div class="card bg-dark">
      <div class="card-body">
        <div class="input-group">
          <input v-model="redTeamSearch" type="text" class="form-control form-control-sm">
          <div class="input-group-append">
            <button class="btn btn-sm btn-dark" type="button">Cerca</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card bg-dark">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-dark">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Severity</th>
              <th scope="col">Titolo</th>
              <th scope="col">Servizio</th>
              <th scope="col">Target</th>
              <th scope="col">Stato</th>
              <th scope="col">Data</th>
              <th scope="col">Azioni</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">48393</th>
              <th scope="row"><span class="badge bg-danger">Critical</span></th>
              <th scope="row">SSl Certificate signed using weak hashing algorithm</th>
              <td>(1900/udp) ssdp</td>
              <td>192.168.1.6</td>
              <td><span class="badge bg-danger">Non risolto</span></td>
              <td>16 Jan 2024</td>
              <td>
                <button class="btn btn-sm btn-dark action" title="Vedi dettaglio">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
                    <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm96 64a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm104 0c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm-72-64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM96 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                  </svg>
                </button>
              </td>
            </tr>
            <tr>
              <th scope="row">48398</th>
              <th scope="row"><span class="badge bg-danger">Critical</span></th>
              <th scope="row">TLS version 1.0 Protocol Detection</th>
              <td>-</td>
              <td>192.168.1.37</td>
              <td><span class="badge bg-danger">Non risolto</span></td>
              <td>16 Jan 2024</td>
              <td>
                <button class="btn btn-sm btn-dark action" title="Vedi dettaglio">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
                    <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm96 64a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm104 0c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm-72-64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM96 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                  </svg>
                </button>
              </td>
            </tr>
            <tr>
              <th scope="row">48396</th>
              <th scope="row"><span class="badge bg-success">Low</span></th>
              <th scope="row">SSL Medium strength cipher suites supported</th>
              <td>-</td>
              <td>192.168.1.37</td>
              <td><span class="badge bg-danger">Non risolto</span></td>
              <td>16 Jan 2024</td>
              <td>
                <button class="btn btn-sm btn-dark action" title="Vedi dettaglio">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
                    <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm96 64a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm104 0c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm-72-64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM96 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: 'RedTeamWidget',
  components :{

  },
  computed : {
    redTeamSearch(){
      return this.$store.getters.getRedTeamSearch;
    }
  },
  data() {
    return {}
  },
  methods:{

  }
}
</script>