export default{
    setAuth(state, value){
        state.auth = value;
    },
    setSpinner(state, value){
        state.spinner = value;
    },
    setMenu(state, value){
        state.menu = value;
    },
    setChats(state, chats){
        state.chats = chats;
    },
    setIDChat(state, idchat){
        state.idchat = idchat;
    },
    setDrawer(state, value){
        state.drawer = value;
    },
    setModalSettings(state, value){
        state.modalSettings = value;
    },
    setModalMitre(state, value){
        state.modalMitre = value;
    },
    setModalTicket(state, value){
        state.modalTicket = value;
    },
    setNewWidgetModal(state, value){
        state.modalNewWidget = value;
    },
    pushMessage(state, message){
        state.messages.push(message);
    },
    setMessages(state, value){
        state.messages = value;
    },
    appendTextMessage(state, char){
        if(state.messages.length) {
            state.messages[state.messages.length - 1].text += char;
        }
    },
    setChatCursor(state, value){
        state.chatCursor = value;
    },
    setTab(state, table){
        state.tab = table;
    },
    setSearchTickets(state,search){
        state.ticketsSearch = search;
    },
    setSearchRedTeam(state,search){
        state.redTeamSearch = search;
    },
    setSearchThreadInt(state,search){
        state.threadIntSearch = search;
    },
    setSearchCSirt(state,search){
        state.CSirtSearch = search;
    },
    //per l'effetto typewriter
    setNumberInterval(state, value){
        state.numberInterval = value
    }
}