<template>
  <Header/>
  <Drawer/>
  <ModalSettings/>
  <ModalMitre/>
  <NewWidgetModal/>
  <div class="wrapper">
    <div class="row">
      <div class="col-lg-6">
        <div id="messages" class="messages" v-if="messages.length" :style="{'max-height' : dynamicHeight}">
          <Message v-for='message in messages' :key='message.id'
                   :message='message'
          />
          <div class="chatcursor" v-show="chatCursor">
            <div class="spinner-grow" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </div>
        <div class="welcome" v-else>
          <img class="icon-init" src="/images/icon-square.png"><br>
          <p class="mt-4 mb-4 text-white">
            Ciao, fammi una domanda.<br>
            Come posso aiutarti oggi?
          </p>
          <div class="row mt-5">
            <h5 class="mt-4 mb-4">Templates</h5>
            <div class="col-lg-6">
              <div class="question-block">
                <p>
                  Puoi mappare sulla ATT&CK Matrix le tecniche e tattiche usate dal rasomware wastedlocker?
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="question-block">
                <p>
                  A cosa si riferisce l'allarme "modifies Gunter's Winlogon Registry Key" sul file NTFVersion.exe?
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="question-block">
                <p>
                  Rispetto alle attività del Thread Intelligence quali sono le attività rispetto alla minaccia X?
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="question-block">
                <p>
                  Quali TTP mitre vengono usate dal gruppo Sandworm Team?
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="question-block">
                <p>
                  Quali sono le vulnerabilità più diffuse per il cliente PagoPA?
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="question-block">
                <p>
                  Qualcuno del CSIRT ha già trovato un malware che ha utilizzato la CVE X?
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="question-block">
                <p>
                  Nel Red Team quanto è diffusa la minaccia X?
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="question-block">
                <p>
                  Qualcuno del CSIRT ha già trovato un malware che ha utilizzato la CVE X?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link" :class="{active : tab === 'operations'}" v-on:click="changeTab('operations')" type="button" role="tab">Operazioni</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" :class="{active : tab === 'editor'}" v-on:click="changeTab('editor')">Editor</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" :class="{active : tab === 'tickets'}" v-on:click="changeTab('tickets')">Tickets</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" :class="{active : tab === 'redteam'}" v-on:click="changeTab('redteam')">Knowledge CVE</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" :class="{active : tab === 'threadint'}" v-on:click="changeTab('threadint')">Thread Int.</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" :class="{active : tab === 'csirt'}" v-on:click="changeTab('csirt')">CSIRT</button>
              </li>
              <li class="nav-item">
                <button class="nav-link plusbutton" v-on:click="toggleNewWidgetModal()">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                  </svg>
                </button>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade" :class="{active : tab === 'operations', show : tab === 'operations'}">
                <OperationsWidget/>
              </div>
              <div class="tab-pane fade" :class="{active : tab === 'editor', show : tab === 'editor'}">
                <EditorWidget/>
              </div>
              <div class="tab-pane fade" :class="{active : tab === 'tickets', show : tab === 'tickets'}">
                <TicketsWidget/>
              </div>
              <div class="tab-pane fade" :class="{active : tab === 'redteam', show : tab === 'redteam'}">
                <RedTeamWidget/>
              </div>
              <div class="tab-pane fade" :class="{active : tab === 'threadint', show : tab === 'threadint'}">
                <ThreadIntelligenceWidget/>
              </div>
              <div class="tab-pane fade" :class="{active : tab === 'csirt', show : tab === 'csirt'}">
                <CSIRTWidget/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ChatBox/>
</template>

<script>
import Message from '@/components/Message.vue'
import ChatBox from "@/components/ChatBox.vue";
import Drawer from "@/components/Drawer.vue";
import Header from "@/components/Header.vue";
import ModalSettings from "@/components/ModalSettings.vue";
import ModalMitre from "@/components/ModalMitre.vue";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import TicketsWidget from "@/components/TicketsWidget.vue";
import RedTeamWidget from "@/components/RedTeamWidget.vue";
import EditorWidget from "@/components/EditorWidget.vue";
import ThreadIntelligenceWidget from "@/components/ThreadIntelligenceWidget.vue";
import CSIRTWidget from "@/components/CSIRTWidget.vue";
import OperationsWidget from "@/components/OperationsWidget.vue";
import NewWidgetModal from "@/components/NewWidgetModal.vue";
export default {
  // eslint-disable-next-line
  name: 'ChatBot',
  computed : {
    messages(){
      return this.$store.getters.getMessages;
    },
    chatCursor(){
      return this.$store.getters.getChatCursor;
    },
    tab(){
      return this.$store.getters.getTab;
    },
    dynamicHeight() {
      return (window.innerHeight - 150) + "px";
    },
    numberInterval() {
      return this.$store.getters.getNumberInterval;
    }
  },
  beforeMount() {
    this.$store.dispatch("getChats");
    let idchat = this.$route.params.idchat;
    if(idchat !== undefined && idchat !== null){
      this.$store.commit("setIDChat", idchat);
      this.$store.dispatch("getChat", {idchat : idchat});
    }
  },
  updated: function () {
    this.scrollBottom();
  },
  mounted(){
    this.scrollBottom();
  },
  beforeUnmount() {
    this.stopInterval();
  },
  components :{
    NewWidgetModal,
    OperationsWidget,
    CSIRTWidget,
    EditorWidget,
    ThreadIntelligenceWidget,
    RedTeamWidget,
    TicketsWidget,
    ModalMitre,
    ModalSettings,
    Header,
    Drawer,
    Message,
    ChatBox
  },
  data() {
    return {
      text : null
    }
  },
  methods:{
    stopInterval(){
      clearInterval(this.numberInterval);
      this.$store.commit("setNumberInterval",null);
    },
    toggleModalTicket(){
      this.$store.commit("setModalTicket", true);
    },
    toggleNewWidgetModal(){
      this.$store.commit("setNewWidgetModal", true);
    },
    scrollBottom(){
      //window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      let el = document.getElementById("messages");
      if(el) {
        el.scrollTop = el.scrollHeight + 100;
      }
    },
    changeTab(tabname){
      this.$store.commit("setTab", tabname);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
