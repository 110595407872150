<template>
  <div class="square-block">
    <div class="card bg-dark">
      <div class="card-body">
        <div class="input-group">
          <input v-model="threadIntelligenceSearch" type="text" class="form-control form-control-sm">
          <div class="input-group-append">
            <button class="btn btn-sm btn-dark" type="button">Cerca</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card bg-dark">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-dark">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Severity</th>
            <th scope="col">Cliente</th>
            <th scope="col">Host</th>
            <th scope="col">Data</th>
            <th scope="col">Azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">748494</th>
            <td><span class="badge bg-danger">High</span></td>
            <td>PagoPA</td>
            <td>192.168.1.2</td>
            <td>16 Jan 2024</td>
            <td>
              <button class="btn btn-sm btn-dark action">
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
                  <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm96 64a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm104 0c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm-72-64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM96 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                </svg>
              </button>
            </td>
          </tr>
          <tr>
            <th scope="row">748494</th>
            <td><span class="badge bg-success">Low</span></td>
            <td>PagoPA</td>
            <td>192.168.1.2</td>
            <td>10 Jan 2024</td>
            <td>
              <button class="btn btn-sm btn-dark action">
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
                  <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm96 64a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm104 0c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm-72-64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM96 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                </svg>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: 'ThreadIntelligenceWidget',
  components :{

  },
  computed : {
    threadIntelligenceSearch(){
      return this.$store.getters.getThreadIntSearch;
    }
  },
  data() {
    return {}
  },
  methods:{

  }
}
</script>