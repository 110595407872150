<template>
  <Spinner/>
  <section class="content">
    <router-view :key="$route.path"/>
  </section>
</template>

<script>
import Spinner from "@/components/Spinner";

export default {
  name: 'App',
  components: {
    Spinner
  }
}
</script>

<style>
body{
  background-color: #172e45;
  color: #fff;
}

.content{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

svg:hover{
  cursor: pointer;
}

.btn, input, textarea{
  border-radius: 0 !important;
}

pre{
  margin: 10px 0 10px 0;
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;
  width: 100%;
  padding: 5px 10px 5px 10px;
  border: 1px solid #fff;
  color: #fff;
  background-color: #000;
}

.wrapper{
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 10px 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.messages{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.square-block{
  width: 100%;
  margin-bottom: 20px;
}

.square-block table{
  max-height: 200px;
  overflow-y: scroll;
}

.editor-block{
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.welcome{
  width: 100%;
  text-align: center;
}

.icon-init{
  border-radius: 50%;
  width: 100px;
}

.chatcursor{
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
}

.prompt-response{
  background-color: #000;
  border: 0;
  height: 100px;
}

.nav-item button{
  color: #fff;
}

.nav-link{
  border-radius: 0;
}

.nav-link:hover{
  color: #fff;
}

.tab-pane{
  margin-top: 20px;
  margin-bottom: 10px;
}

.ql-editor{
  color: #fff;
}

.action{
  text-align: center;
  margin-right: 5px;
  width: 35px;
  height: 35px;
}

.action svg{
  fill: #fff;
}

table td, table th{
  font-size: 14px;
}

.question-block{
  margin-bottom: 20px;
  border: 1px solid #fff;
  padding: 10px;
  height: 100px;
}

.btn-dark:hover{
  border-color: #7a7a7a;
  background-color: #7a7a7a;
}

.plusbutton{
  padding: 6px 15px 10px 15px;
  width: 46px;
  height: 42px;
}

.plusbutton svg{
  fill: #fff;
}

</style>
