<template>
  <div class="message">
      <div class="d-flex">
        <div class="flex-shrink-0">
          <img src="/images/icon-square.png" v-if="message.author === 'Chatbot'" class="chat-profile border-red" alt="Chatbot">
          <img src="/images/user.png" v-else class="chat-profile border-user" alt="Chat Profile">
        </div>
        <div class="flex-grow-1 ms-3">
          <h3>{{message.author}} <small class="text-muted"><i>ha scritto il {{message.date}}</i></small></h3>
          <div class="text-message typewriter" v-html="message.text"></div>
          <div class="image" v-if="message.image">
            <img class="img-fluid mt-3" :src="'/images/' + message.image">
          </div>
          <div class="icons-message">
            <div class="icon" v-on:click="copyToClipboard(message.text)" title="Copy to clipboard">
              <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
                <path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"/>
              </svg>
            </div>
            <div class="icon" v-if="message.mitre" v-on:click="openModalMitre()" title="Show MITRE">
              <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                <path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8V444.8C394 378 431.1 230.1 432 141.4L256 66.8l0 0z"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'Message',
  props: [
    'message'
  ],
  methods:{
    openModalMitre(){
      this.$store.commit("setModalMitre",true);
    },
    copyToClipboard(text){
      var regex = /<br\s*[/]?>/gi;
      navigator.clipboard.writeText(text.replace(regex, "\n"));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.message {
  margin-bottom: 10px;
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
  color: #fff;
}

.message h3{
  font-size: 14px;
}

.text-message{
  width: 100%;
  box-sizing: border-box;
  line-break: auto;
}

.text-message h5{
  font-size: 10px !important;
  margin-top: 15px;
  margin-bottom: 10px;
}

.message .text-message, .message .text-message h5 {
  font-size: 15px;
}

.chat-profile{
  border-radius: 50%;
  width: 40px;
}

.border-red{
  border: 2px solid #fff;
}

.border-user{
  border: 2px solid #7a7a7a;
}

.icons-message{
  margin-top: 15px;
  width: 100%;
  overflow: hidden;
}

.icons-message .icon{
  float: left;
  padding-right: 8px;
}

.icons-message svg{
  fill: #fff;
}
</style>
