<template>
  <div class="drawer" v-if="drawer">
    <div class="drawer-content drawer-content-scrollable">
      <div class="drawer-header">
        <div class="close">
          <svg class="fill-white" v-on:click="toggleDrawer" xmlns="http://www.w3.org/2000/svg" height="25" width="21" viewBox="0 0 384 512">
            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
          </svg>
        </div>
        <br>
        <button class="btn btn-dark w-100 mb-3" v-on:click="newChat()">
          Nuova chat
        </button>
        <h5 class="drawer-title">Cronologia chat</h5>
      </div>
      <div class="drawer-body">
        <template v-if="chats.length">
          <ul class="menu-list">
            <template v-for="(chat,index) in chats" :key="index">
              <li :class="{active : chat.id === idChat}">
                <div class="d-flex justify-content-between">
                  <router-link :to="'/chats/' + chat.id" v-on:click="toggleDrawer">{{chat.name}}</router-link>
                  <div class="chat-buttons">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                      <path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"/>
                    </svg>
                    <svg v-on:click="deleteChat(chat.id)" class="fill-red" xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
                      <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/>
                    </svg>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </template>
        <template v-else>
          <p class="mt-4 mb-4">There are no chats here</p>
        </template>
      </div>
      <div class="drawer-footer">
        <button class="btn btn-dark w-100 mb-3" v-on:click="logout()">
          Logout
        </button>
      </div>
    </div>
  </div>
  <div class="drawer-layout" v-on:click="toggleDrawer" v-if="drawer"></div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'Drawer',
  data() {
    return {}
  },
  computed: {
    drawer(){
      return this.$store.getters.getDrawer;
    },
    chats(){
      return this.$store.getters.getChats;
    },
    idChat(){
      return this.$store.getters.getIdChat;
    }
  },
  methods : {
    newChat(){
      this.$store.dispatch("newChat");
    },
    deleteChat(idchat){
      if(confirm("Do you want to delete this chat?")){
        this.$store.dispatch("deleteChat",idchat);
      }
    },
    toggleDrawer(){
      this.$store.commit("setDrawer", !this.drawer);
    },
    logout(){
      this.$store.dispatch("logout");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

p{
  font-size: 14px;
}
.btn{
  border-radius: 0;
}
.drawer{
  position:fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 100%;
  z-index: 1100;
  background-color: #000;
}

.drawer svg{
  fill: #fff;
}
.drawer .close{
  width: 100%;
  text-align: right;
}

.drawer-content{
  padding: 0 15px 60px 15px;
  height: 100%;
  overflow-y: scroll;
}

.drawer-header{
  padding: 8px 0 8px 0;
  background-color: #000;
  color: #fff;
  border-bottom: 1px solid #7a7a7a;
}
.drawer-header h5{
  font-weight: normal;
  font-size: 14px;
}
.menu-list{
  margin: 0;
  padding: 0;
  max-height: 430px;
  overflow-y: scroll;
  list-style: none;
}
.menu-list li{
  padding: 10px 5px 10px 5px;
}
.menu-list li a{
  display: block;
  text-decoration: none;
  color: #fff;
}

.menu-list li.active{
  background-color: #1c1f23;
}

.drawer-footer{
  padding: 15px 0 15px 0;
  background-color: #000;
  color: #fff;
}

label{
  font-size: 12px;
}

.drawer-layout{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  z-index: 1090;
}

.chat-buttons svg{
  margin: 4px;
}

.chat-buttons .fill-red{
  fill: #dc3545;
}
</style>
