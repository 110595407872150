<template>
  <div class="col-lg-12">
    <div class="container">
      <div class="row gx-lg-5 align-items-center">
        <div class="col-lg-12 mb-5 mt-5">
          <div class="card login-form">
            <div class="card-body py-5 px-md-5">
              <div class="login-image">
                <img class="img-fluid" src="images/icon-chat.png">
              </div>
              <LoginForm/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
export default {
  // eslint-disable-next-line
  name: 'Login',
  components :{
    LoginForm
  },
  data() {
    return {

    }
  },
  methods:{

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-form{
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
}

.login-image{
  margin: 0 auto;
  max-width: 100px;
}

.login-image img{
  margin-bottom: 30px;
  display: block;
}
</style>
