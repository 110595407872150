import axios from "axios";
import router from "@/router";
import {URL_API_EBE} from "@/constants/constants";

export default {
    async sendMessage({commit,state,dispatch}, message = {}) {
        //reqMessage e resMessage sono dell'EBE backend
        let token = localStorage.getItem("token");
        let reqMessage = {
            "idchat": message.idchat,
            "messages" : [
                {"content" : message.text, "role" : "user"},
            ],
            "model" : "llama-2-7b-chat"
        }
        await axios.post(URL_API_EBE + "chat", reqMessage,{
            headers: {Authorization: `Bearer ${token}`}
        }).then((response) => {
            var resMessage = {
                id : response.data.id,
                idauthor : 0,
                idchat : message.idchat,
                author : "Chatbot",
                text : response.data.message.content,
                date : message.date,
                table : response.data.table,
                mitre : false,
            };
            if(response.data.message.table){
                let table = response.data.message.table;
                let search = response.data.message.search;
                commit("setTab",table);
                if (table === "tickets") {
                    commit("setSearchTickets",search);
                } else if (table === "redteam") {
                    commit("setSearchRedTeam",search);
                } else if (table === "threadint") {
                    commit("setSearchThreadInt",search);
                } else if (table === "csirt") {
                    commit("setSearchCSirt",search);
                }
            }
            var totaltext = response.data.message.content;
            //devo salvare ogni messaggio nel mio db
            let mexDb = [
                {
                    "idchat": message.idchat,
                    "text" : message.text,
                    "date" : message.date
                },
                resMessage
            ];
            axios.post("messages", {messages : mexDb},{
                headers: {Authorization: `Bearer ${token}`}
            }).then(() => {
                commit("setChatCursor", false);
                resMessage.text = "";
                state.messages.push(resMessage);//inserisco il messaggio di risposta del chatbot
                router.push("/chats/" + state.idchat);
                dispatch("typeWriter", totaltext);
            }).catch((error) => {
                if(error.response.status === 401) {
                    state.getters.manageAuth();//DA RIVEDERE
                }else {
                    commit("setChatCursor", false);
                    console.log(error);
                }
            });
        }).catch((error) => {
            if(error.response.status === 401) {
                state.getters.manageAuth();//DA RIVEDERE
            }else{
                commit("setChatCursor", false);
                console.log(error);
            }
        });
    },
    typeWriter({commit}, totaltext){
        let i = 0;
        let el = document.getElementById("messages");
        if (i < totaltext.length) {
            commit("appendTextMessage",totaltext.charAt(i));
            i++;
            let interval = setInterval(() => {
                commit("setNumberInterval",interval);
                if (i < totaltext.length) {
                    commit("appendTextMessage", totaltext.charAt(i));
                    i++;
                    if(el) {
                        el.scrollTop = el.scrollHeight + 100;
                    }
                }else{
                    clearInterval(interval);
                }
            }, 18);
        }
    },
    changeModel({commit}){
        commit("setSpinner",true);
        commit("setDrawer",false);
        commit("setModalSettings",false);
        setTimeout(() => {
            commit("setSpinner",false);
        },2000);
    },
    async getChats({state,commit}) {
        let token = localStorage.getItem("token");
        commit("setMessages",[]);
        commit("setSpinner",true);
        await axios.post('chats', {},{
            headers: {Authorization: `Bearer ${token}`}
        }).then((response) => {
            commit("setSpinner",false);
            state.chats = response.data.chats;
        }).catch(error => {
            commit("setSpinner",false);
            console.log(error);
        });
    },
    async getChat({state,commit},payload) {
        let token = localStorage.getItem("token");
        commit("setSpinner",true);
        await axios.post('chats/' + payload.idchat, {},{
            headers: {Authorization: `Bearer ${token}`}
        }).then((res) => {
            commit("setSpinner",false);
            state.messages = res.data.chat.messages;
        }).catch(error => {
            commit("setSpinner",false);
            console.log(error);
        });
    },
    async newChat({dispatch,commit},message = null) {
        commit("setSpinner",true);
        commit("setDrawer",false);
        let token = localStorage.getItem("token");
        await axios.post('chats/create', {},{
            headers: {Authorization: `Bearer ${token}`}
        }).then((res) => {
            let idchat = res.data.chat.id;
            commit("setSpinner",false);
            if(!message){
                //solo new chat
                router.push("/chats/" + res.data.chat.id);
            }else{
                //se c'é il message ma non c'é idchat
                message.idchat = idchat;
                commit("setIDChat", idchat);
                commit("pushMessage", message);//inserisco il messaggio inviato dall'utente
                dispatch("sendMessage", message);
            }
        }).catch(error => {
            commit("setSpinner",false);
            console.log(error);
        });
    },
    async deleteChat({commit,dispatch},idchat) {
        let token = localStorage.getItem("token");
        commit("setSpinner",true);
        await axios.delete('chats/' + idchat,{
            headers: {Authorization: `Bearer ${token}`}
        }).then(() => {
            commit("setSpinner",false);
            commit("setDrawer",false);
            commit("setIDChat",null);
            dispatch("getChats");
            router.push("/");
        }).catch(error => {
            commit("setSpinner",false);
            commit("setDrawer",false);
            console.log(error);
        });
    },
    async logout({commit}) {
        let token = localStorage.getItem("token");
        commit("setSpinner",true);
        commit("setDrawer",false);
        await axios.post('logout', {},{
            headers: {Authorization: `Bearer ${token}`}
        }).then(() => {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshtoken');
            localStorage.removeItem("expires");
            localStorage.removeItem("user");
            commit("setAuth", false);
            commit("setSpinner",false);
            router.push('/login');
        }).catch(error => {
            console.log(error);
        });
    },
}