<template>
  <div class="modal" :class="{show : modalMitre}">
    <div class="modal-dialog modal-xxl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">MITRE</h5>
          <button type="button" class="btn-close" v-on:click="toggleMitre"/>
        </div>
        <div class="modal-body">
          <div class="col-lg-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link" :class="{active : tab === 'graph'}" v-on:click="changeTab('graph')" type="button" role="tab">Graph</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" :class="{active : tab === 'mitre'}" v-on:click="changeTab('mitre')">MITRE</button>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade" :class="{active : tab === 'graph', show : tab === 'graph'}">
                <div class="graph">
                  <v-network-graph :nodes="nodes" :edges="edges" :layouts="layouts"></v-network-graph>
                </div>
              </div>
              <div class="tab-pane fade" :class="{active : tab === 'mitre', show : tab === 'mitre'}">
                <iframe width="100%" height="450" src="https://mitre-attack.github.io/attack-navigator/"></iframe>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" v-on:click="toggleMitre">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line
  name: 'ModalMitre',
  components: {},
  data() {
    return {
      nodes : {
        node1: { name: "TTP 120" },
        node2: { name: "TTP 121" },
        node3: { name: "TTP 122" },
        node4: { name: "TTP 123" },
      },
      edges : {
        edge1: { source: "node1", target: "node2", label: "1 Gbps" },
        edge2: { source: "node2", target: "node3", label: "100 Mbps" },
        edge3: { source: "node2", target: "node4", label: "100 Mbps" },
      },
      layouts : {
        nodes: {
          node1: { x: 0, y: 80 },
          node2: { x: 200, y: 80 },
          node3: { x: 360, y: 0 },
          node4: { x: 360, y: 160 },
        }
      },
      tab : "graph"
    }
  },
  computed: {
    modalMitre(){
      return this.$store.getters.getModalMitre;
    }
  },
  methods : {
    toggleMitre(){
      this.$store.commit("setModalMitre", !this.modalMitre);
    },
    changeTab(tabname){
      this.tab = tabname;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-dialog {
  z-index: 1;
}

.modal-body{
  height: 500px;
}

.show{
  display: block;
}

.modal-dialog {
  z-index: 1;
}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

label{
  color: #000;
}

.modal-xxl{
  max-width: 1200px;
}

.graph{
  width: 100%;
  height: 450px;
}

.nav-link{
  color: #7a7a7a;
}
</style>
