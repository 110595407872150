<template>
  <div class="square-block">
    <div class="card bg-dark">
      <div class="card-header">
        File upload
      </div>
      <div class="card-body">
        <div class="mb-3">
          <label for="file" class="form-label">Inserisci un file da fare analizzare dall'AI</label>
          <div class="input-group">
            <input class="form-control form-control-sm" id="file" type="file">
            <div class="input-group-append">
              <button class="btn btn-sm btn-dark" type="button">Upload</button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-dark">
            <thead>
            <tr>
              <th scope="col">File #</th>
              <th scope="col">Title</th>
              <th scope="col">Date</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th scope="row">1</th>
              <td>File 1</td>
              <td>16 Jan 2024</td>
              <td>
                <button class="btn btn-sm btn-dark action" title="Analizza con AI">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
                    <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm96 64a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm104 0c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24H448c13.3 0 24 10.7 24 24s-10.7 24-24 24H224c-13.3 0-24-10.7-24-24zm-72-64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM96 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
                  </svg>
                </button>
                <button class="btn btn-sm btn-dark action" title="Elimina file">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
                    <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/>
                  </svg>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!--<div class="square-block">
    <div class="card bg-dark">
      <div class="card-header">
        MITRE (TTPs)
      </div>
      <div class="card-body">
        <p class="card-text">Generate MITRE knowledge base on the last response</p>
        <a href="#" class="btn btn-sm btn-primary" v-on:click="openModalMitre">Generate</a>
      </div>
    </div>
  </div>-->
  <div class="square-block">
    <div class="card bg-dark">
      <div class="card-header">
        Query prompt
      </div>
      <div class="card-body">
        <div class="promptresults">
          <div class="prompt-response"></div>
        </div>
        <div class="input-group mb-3">
          <input type="text" class="form-control form-control-sm" placeholder="Inserisci un comando">
          <div class="input-group-append">
            <button class="btn btn-sm btn-dark" type="button">Invia</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: 'OperationsWidget',
  components :{},
  data() {
    return {}
  },
  methods:{}
}
</script>