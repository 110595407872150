import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import NotFound from "@/views/NotFound.vue";


const routes = [
  {
    path: "/",
    alias: "/chats/:idchat",
    name: "Home",
    component: Home,
    meta : {
      auth: true,
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta : {
      auth: false,
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFound,
    meta : {
      auth: false,
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to,from, savedPosition) {
    if (to.name === "Login") {
      return {top: 0};
    }else if(savedPosition){
      return savedPosition
    }
  }
});

router.beforeEach((to, from, next) => {
  store.getters.manageAuth;
  if(to.meta.auth && !store.getters.getAuth){
    //this.$store.dispatch("Logout");
    next('/login');
  } else {
    next();
  }
});

export default router
