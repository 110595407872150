<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card bg-dark">
        <div class="card-body">
          <div class="editor-block">
            <QuillEditor :height="300" v-model:content="text" />
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success float-end mt-2 btn-sm" v-on:click="generatePDF()">Esporta in PDF</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill';
import axios from "axios";
export default {
  // eslint-disable-next-line
  name: 'EditorWidget',
  components :{
    QuillEditor
  },
  data() {
    return {}
  },
  methods:{
    async generatePDF(){
      if(this.text) {
        let content = this.text.ops[0].insert;
        this.$store.commit("setSpinner", true);
        let token = localStorage.getItem("token");
        await axios.post('generatepdf', {
          "text": content
        }, {
          headers: {Authorization: `Bearer ${token}`},
          //responseType: 'blob'
        }).then((res) => {
          this.$store.commit("setSpinner", false);
          window.open(res.data.path, '_blank');
          /*
          const file = new Blob(
              [res.data],
              {type: 'application/pdf'});
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL, '_blank');
          */
        }).catch(error => {
          this.$store.commit("setSpinner", false);
          console.log(error);
        });
      }
    }
  }
}
</script>
<style scoped>

</style>