import Vuex from "vuex";
import getters from '@/services/getters';
import mutations from '@/services/mutations';
import actions from '@/services/actions';
export default new Vuex.Store({
    state: {
        auth : false,
        spinner : false,
        menu : false,
        drawer : false,
        modalSettings : false,
        modalMitre : false,
        modalTicket : false,
        modalNewWidget : false,
        messages : [], //messaggi contenuti nella chat,
        chatCursor : false,
        chats : [], //array di tutte le chat dell'utente
        idchat : null, //id della chat selezionata
        tab : "operations", //variabile per tab selezionata
        ticketsSearch : "",
        redTeamSearch : "",
        threadIntSearch : "",
        CSirtSearch : "",
        numberInterval: null
    },
    getters,
    mutations,
    actions,
    modules: {}
});
