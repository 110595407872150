<template>
  <div class="modal" :class="{show : modalNewWidget}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">Nuovo widget</h5>
          <button type="button" class="btn-close" v-on:click="toggleNewWidgetModal"/>
        </div>
        <div class="modal-body">
          <div class="input group mb-3">
            <label>Titolo Widget</label>
            <input class="form-control" type="text" v-model="title">
          </div>
          <div class="input group mb-3">
            <label>Endpoint API</label>
            <input class="form-control" type="text" v-model="endpoint">
          </div>
          <div class="input group mb-3">
            <label>API KEY</label>
            <input class="form-control" type="text" v-model="apikey">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm" v-on:click="toggleNewWidgetModal">Chiudi</button>
          <button type="button" class="btn btn-dark btn-sm">Aggiungi widget</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'NewWidgetModal',
  data() {
    return {
      title : null,
      endpoint : null,
      apikey : null
    }
  },
  computed: {
    modalNewWidget(){
      return this.$store.getters.getModalNewWidget;
    }
  },
  methods : {
    toggleNewWidgetModal(){
      this.$store.commit("setNewWidgetModal", !this.modalNewWidget);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-dialog {
  z-index: 1;
}

.show{
  display: block;
}

.modal-dialog {
  z-index: 1;
}

.modal:after {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

label{
  color: #000;
}
</style>
