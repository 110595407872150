<template>
  <div class="chat-box bg-dark">
    <form @submit.prevent="onSubmit()">
      <div class="input-group">
        <input type="text" class="form-control" v-model="text" placeholder="Scrivi un messaggio qui">
        <div class="input-group-append">
          <button class="btn btn-chatbox" type="button" v-on:click="resetMessage">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
              <path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>
            </svg>
          </button>
          <button class="btn btn-chatbox" type="submit">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
              <path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"/>
            </svg>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line
  name: 'ChatBox',
  data() {
    return {
      text: ""
    }
  },
  components : {

  },
  computed: {
    idChat(){
      return this.$store.getters.getIdChat;
    },
  },
  methods : {
    onSubmit() {
      if(this.text.length) {
        this.$store.commit("setChatCursor", true);
        const date = new Date();
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2,"0");
        let day = String(date.getDate()).padStart(2,"0");
        let hour = ("0" + date.getHours()).slice(-2);
        let minutes = ("0" + date.getMinutes()).slice(-2);
        let message = {
          idchat: this.idChat,
          text: this.text,
          date: day + "/" + month + "/" + year + " " + hour + ":" + minutes
        };
        if(this.idChat === undefined || this.idChat === null) {
          //devo creare una nuova chat e inviare un messaggio
          this.$store.dispatch("newChat", message);
        }else {
          this.$store.commit("pushMessage", message);
          this.$store.dispatch("sendMessage", message);
          this.text = "";
        }
      }
    },
    resetMessage(){
      this.text = "";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn{
  border-radius: 0;
}

.chat-box{
  background-color: #ffffff;
  border-top: 1px solid #000;
  padding: 10px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.chat-box input{
  border-radius: 0;
  border: 0;
}

.btn-chatbox{
  background-color: #000;
}

.btn-chatbox svg{
  fill: #fff !important;
}

.fill-white{
  fill: #fff;
}

.fill-white:hover{
  cursor: pointer;
}
</style>
